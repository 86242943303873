import { Typography } from "@mui/material";
import Grid from "components/nuvel/Grid";
import TabPanel from "components/nuvel/TabPanel";
import TextField from "components/nuvel/TextField";
import { NotaDeSaidaSerializer } from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import { ChangeEvent } from "react";
interface ObservacaoTabProps {
  tabs: number;
  stateNota: NotaDeSaidaSerializer;
  handleChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => void;
}

const ObservacaoTab = ({
  tabs,
  stateNota,
  handleChange,
}: ObservacaoTabProps) => {
  return (
    <TabPanel value={tabs} index={2}>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <TextField
            label="Informações Adicionais"
            name="informacoes_adicionais_interesse_fisco"
            value={stateNota.informacoes_adicionais_interesse_fisco}
            onChange={handleChange}
            multiline
            rows={4}
            inputProps={{ maxLength: 2000 }}
            fullWidth
          />
        </Grid>
        <Grid style={{ alignSelf: "center" }}>
          <Typography variant="h6">{stateNota.natureza_operacao}</Typography>
        </Grid>
        <Grid xs={8}>
          <TextField
            label="Natureza da Operação - Informações Complementares"
            name="natureza_operacao_desc"
            value={stateNota.natureza_operacao_desc}
            onChange={handleChange}
            inputProps={{ maxLength: 2000 }}
            fullWidth
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default ObservacaoTab;
