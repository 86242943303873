export enum ReportRequestType {
  PDF = 1,
  XLS = 2,
}

export enum ReportRequestStatus {
  PENDING = 1,
  PROCESSING = 2,
  PROCESSING_DOCUMENT = 3,
  COMPLETED = 4,
  ERROR = 5,
}

export interface ReportRequestSerializer {
  id: number;
  title: string;
  tipo: ReportRequestType;
  model: string;
  filters: object;
  values: object;
  annotate: object;
  keys: object;
  totals: object;
  exclude: object;
  extra: object;
  order_by: object;
  related_fields: object;
  distinct: boolean;
  group_by: object;
  report_info: object;
  created_at: string;
  updated_at: string;
  file: string;
  total_records: number;
  total_processed: number;
  status: ReportRequestStatus;
  error: string;
  request_by: number;
}
