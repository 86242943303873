import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelModal from "components/nuvel/Modal";
import PlanoDeContaPagamentoSelect from "components/nuvel/plano_de_contas/pagamento";
import NuvelTextField from "components/nuvel/TextField";
import { TransacaoSerializer } from "data/interfaces/financeiro/TransacaoSerializer";
import { TransacaoModel } from "data/models";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { handleChange } from "utils/functions";
import { NuvelSelectValue } from "utils/types";

interface IModalCreate {
  modal: {
    open: boolean;
    id: number;
    transacao: TransacaoSerializer | undefined;
  };
  handleModal: (id: number) => void;
  id: number;
  mutate: () => void;
}

const defaultState: TransacaoSerializer = {
  descricao: "",
  emissao: dayjs(),
  loja: undefined,
  parceiro_de_negocios: undefined,
  plano_de_contas: undefined,
  tipo: 1,
  status: 0,
  qnt_prestacoes: 1,
  valor: "",
  prestacoes: [],
};

const ModalCreate: React.FC<IModalCreate> = ({
  modal,
  handleModal,
  id,
  mutate,
}) => {
  const [state, setState] = useState<TransacaoSerializer>(defaultState);

  useEffect(() => {
    if (modal.transacao) {
      setState(modal.transacao);
    }
  }, [modal.transacao]);

  useEffect(() => {
    setState({
      ...state,
      prestacoes: Array.from(
        { length: Number(state.qnt_prestacoes) },
        (_, index) => ({
          vencimento: dayjs(state.emissao).add(index + 1, "month"),
          numero: index + 1,
          valor: (Number(state.valor) / Number(state.qnt_prestacoes)).toFixed(
            4
          ),
          status: 0,
        })
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.qnt_prestacoes, state.valor, state.emissao]);

  return (
    <NuvelModal
      title="Cadastrar Conta a Pagar"
      open={id === modal.id && modal.open}
      btnCancel={() => {
        setState(defaultState);
        handleModal(id);
      }}
      btnSave={() => {
        TransacaoModel.save(state).then(() => {
          mutate();
          setState(defaultState);
          handleModal(id);
        });
      }}
      fullWidth
      maxWidth="md"
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid size={{ xs: 12 }}>
          <NuvelTextField
            label="Descrição"
            name="descricao"
            value={state.descricao}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12, md: 4 }}>
          <NuvelAutocomplete
            label="Loja"
            name="loja"
            value={Number(state.loja)}
            onChange={(e) => handleChange(e, setState)}
            reference="loja"
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12, md: 4 }}>
          <NuvelAutocomplete
            label="Fornecedor"
            name="parceiro_de_negocios"
            value={Number(state.parceiro_de_negocios)}
            onChange={(e) => handleChange(e, setState)}
            reference="fornecedor"
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12, md: 4 }}>
          <PlanoDeContaPagamentoSelect
            value={Number(state.plano_de_contas)}
            name="plano_de_contas"
            onChange={(e: {
              target: { value: NuvelSelectValue; name: string };
            }) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12, md: 4 }}>
          <NuvelDatePicker
            label="Emissão"
            onChange={(e) => handleChange(e, setState)}
            name="emissao"
            value={state.emissao}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12, md: 4 }}>
          <NuvelTextField
            label="Valor"
            name="valor"
            value={state.valor}
            onChange={(e) => handleChange(e, setState)}
            money
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12, md: 4 }}>
          <NuvelTextField
            label="Qnt. Parcelas"
            name="qnt_prestacoes"
            value={state.qnt_prestacoes}
            onChange={(e) => handleChange(e, setState)}
            type="number"
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Número</TableCell>
                  <TableCell>Vencimento</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.prestacoes?.slice(0, 50).map((prestacao, index) => (
                  <TableRow key={prestacao.numero}>
                    <TableCell>{prestacao.numero}</TableCell>
                    <TableCell>
                      <NuvelDatePicker
                        name="vencimento"
                        value={prestacao.vencimento}
                        onChange={(e) => {
                          if (state.prestacoes) {
                            const _v = [...state.prestacoes];
                            _v[index].vencimento = e.target.value;
                            setState({ ...state, prestacoes: _v });
                          }
                        }}
                        noStyle
                      />
                    </TableCell>
                    <TableCell>{prestacao.valor?.toBRL()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </NuvelGrid>
      </NuvelGrid>
    </NuvelModal>
  );
};

export default ModalCreate;
