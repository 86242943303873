import * as React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import NuvelGrid from "components/nuvel/Grid";
import NuvelPaper from "components/nuvel/Paper";
import { useNavigate } from "react-router-dom";
import useReport from "hooks/useReport";

import {
  SimpleListChild,
  SimpleTableColumn,
} from "components/nuvel/lists/simple";
import { SimpleList } from "components/nuvel/lists";
import printJS from "print-js";
import { showMessage } from "utils/functions/dialog";

export interface Relatorio {
  url: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<SimpleTableColumn<any>>;
  totais?: string;
  filtros: {
    [key: string]: string | number | string[] | number[] | null | undefined;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childs?: Array<SimpleListChild<any>>;
}

interface Props {
  title: string;
  relatorio: number;
  relatorios: Relatorio[];
  children?: React.ReactNode;
}

const DefaultRelatorios: React.FC<Props> = ({
  children,
  title,
  relatorio,
  relatorios,
}) => {
  const navigate = useNavigate();
  const { loading, report, handleSubmit } = useReport(relatorios, relatorio);

  return (
    <form noValidate={true} onSubmit={handleSubmit} style={{ height: "100%" }}>
      <NuvelGrid container spacing={2} sx={{ height: "100%" }}>
        <NuvelGrid xs={12} display="flex" flexDirection="column">
          <NuvelPaper sx={{ height: "100%" }}>
            <Box p={2}>
              <Typography variant="h4">Relatório de {title}</Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <NuvelGrid container spacing={2}>
                <NuvelGrid xs={12}>{children}</NuvelGrid>
                <NuvelGrid xs={12}>
                  <Divider />
                </NuvelGrid>
                <NuvelGrid xs={12}>
                  <SimpleList
                    columns={relatorios[relatorio].columns || []}
                    data={report.data.rows || []}
                    total_pages={
                      Math.ceil(report.data.totalCount / 10) || undefined
                    }
                    page={report.page}
                    handleChangePage={(_, page) => {
                      handleSubmit(undefined, page);
                    }}
                    totals={report.data.totais || undefined}
                    childs={report.config.childs || undefined}
                  />
                </NuvelGrid>
              </NuvelGrid>
            </Box>
          </NuvelPaper>
          <Box display="flex" pt={2}>
            <Box flexGrow="1">
              <Button variant="contained" onClick={() => navigate(-1)}>
                Voltar
              </Button>
            </Box>
            <Box>
              <Button
                disabled
                color="primary"
                variant="contained"
                sx={{ mr: 1 }}
              >
                Exportar XLS
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => {
                  handleSubmit(undefined, 1, 10, true, (report) => {
                    if (
                      report.response.headers["content-type"] !==
                      "application/pdf"
                    ) {
                      // Blob to json
                      report.response.data.text().then((text: string) => {
                        const data = JSON.parse(text);
                        showMessage(
                          `Seu relatório contém ${data.total_nodes} ` +
                            `dados em ${data.total_records} linhas` +
                            ", portanto ele será gerado em segundo plano, acesse a lista de relatórios para acompanhar o status " +
                            "ID: " +
                            data.async_report_id,
                          "Relatório está sendo gerado",
                          () => {
                            navigate("/retaguarda/relatorios/requests");
                          }
                        );
                      });
                      return;
                    }
                    const pdfUrl = URL.createObjectURL(report.response.data);
                    printJS({
                      printable: pdfUrl,
                      onPrintDialogClose: () => {
                        URL.revokeObjectURL(pdfUrl);
                      },
                    });
                  });
                }}
              >
                Imprimir
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                color="primary"
              >
                Consultar
              </Button>
            </Box>
          </Box>
        </NuvelGrid>
      </NuvelGrid>
    </form>
  );
};

export default DefaultRelatorios;
