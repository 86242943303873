import {
  AddCircle,
  Cancel,
  PauseCircleFilled,
  RemoveCircle,
} from "@mui/icons-material";
import {
  CircularProgress,
  debounce,
  Divider,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NuvelAutocomplete, {
  NuvelAutocompleteEvent,
} from "components/nuvel/Autocomplete";
import Grid from "components/nuvel/Grid";
import Modal from "components/nuvel/Modal";
import Select from "components/nuvel/Select";
import TextField from "components/nuvel/TextField";
import {
  CAMPOS_IMPOSTO_TO_VALID_NOME,
  CSOSN,
  CST_LIST,
} from "constants/shared/impostos";
import { ProdutoListSerializer } from "data/interfaces/estoque/ProdutoListSerializer";
import { ImpostoSerializer } from "data/interfaces/financeiro/ImpostoSerializer";
import { NotaDeSaidaItensSerializer } from "data/interfaces/financeiro/NotaDeSaidaItensSerializer";
import {
  NotaDeSaidaSerializer,
  NotaDeSaidaSerializerFinalidadeEnum,
} from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import { CalculaImpostoSerializer } from "data/interfaces/sefaz/calc_imposto";
import produto from "data/models/estoque/produto";
import calc_imposto from "data/models/sefaz/calc_imposto";
import React, { useCallback } from "react";
import { handleChange } from "utils/functions";
import { incluirItemState } from "../states";
import { getTotaisNota } from "../utils";

const useStyles = makeStyles((theme: Theme) => ({
  esquerda: {},
  direita: {
    borderLeft: "solid 1px rgba(0, 0, 0, 0.12)",
  },
  comum: {
    padding: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const PlusIcon = () => (
  <Grid
    xs={2}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <AddCircle fontSize="small" />
  </Grid>
);

const EqualIcon = () => (
  <Grid
    xs={2}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <PauseCircleFilled
      style={{ transform: "rotate(90deg)" }}
      fontSize="small"
    />
  </Grid>
);

const MinusIcon = () => (
  <Grid
    xs={2}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <RemoveCircle fontSize="small" />
  </Grid>
);

interface InclusaoDeItemProps {
  stateNotaItem: NotaDeSaidaItensSerializer;
  setStateNota: React.Dispatch<React.SetStateAction<NotaDeSaidaSerializer>>;
  setStateNotaItem: React.Dispatch<
    React.SetStateAction<NotaDeSaidaItensSerializer>
  >;
  stateNota: NotaDeSaidaSerializer;
  modalSec: { id: number; open: boolean };
  handleSecModal: (id: number) => void;
  listProds: { rows: ProdutoListSerializer[] };
}

function InclusaoDeItem(props: InclusaoDeItemProps) {
  const {
    stateNotaItem,
    setStateNotaItem,
    setStateNota,
    stateNota,
    modalSec,
    handleSecModal,
    listProds,
  } = props;
  const classes = useStyles();
  const [impostoPreview, setImpostoPreview] =
    React.useState<CalculaImpostoSerializer | null>(null);
  const [loadingImposto, setLoadingImposto] = React.useState(false);

  const { data: prodData } = produto.useModel({
    id: stateNotaItem.produto ? stateNotaItem.produto : undefined,
  });
  const prod = prodData?.rows.find((x) => x.id === stateNotaItem.produto);
  const impostoObj = stateNotaItem.imposto_obj;

  const onClose = () => {
    setStateNotaItem(incluirItemState);
    setImpostoPreview(null);
    handleSecModal(1);
  };

  const totals = () => {
    if (!prod)
      return {
        subtotal: 0,
        calc_desc: 0,
        calc_acres: 0,
        total: 0,
      };
    const subtotal =
      stateNotaItem.preco_venda * (stateNotaItem.quantidade || 0);
    const calc_desc = ((stateNotaItem.desconto || 0) / 100) * subtotal;
    const calc_acres = ((stateNotaItem.acrescimo | 0) / 100) * subtotal;
    const total =
      subtotal + calc_acres - calc_desc + Number(stateNotaItem.frete || 0);
    return {
      subtotal: subtotal.round(2),
      calc_desc: calc_desc.round(2),
      calc_acres: calc_acres.round(2),
      total: total.round(2),
    };
  };

  const recalculaImposto = async (
    prod_state: NotaDeSaidaItensSerializer,
    emitente: number,
    imposto_obj: ImpostoSerializer
  ) => {
    if (!emitente || !prod_state.produto || !imposto_obj?.id) {
      setImpostoPreview({
        error: "Selecione um emitente, um produto e um imposto",
      });
      return;
    }
    setLoadingImposto(true);
    calc_imposto
      .get(undefined, {
        imposto: imposto_obj?.id,
        produto: prod_state.produto,
        loja: emitente,
        preco_total: prod_state.subtotal,
        desconto: prod_state.calc_desc,
        acrescimo: prod_state.calc_acres,
        frete: prod_state.frete,
        is_nfe: true,
        p_devol: prod_state.porcentagem_devolvida,
      })
      .then((res) => {
        setImpostoPreview(res);
        setLoadingImposto(false);
      })
      .catch((err) => {
        setImpostoPreview({
          error: `Erro ao calcular imposto: ${
            err.response?.data?.detail ?? err.response?.data?.message
          }`,
        });
        setLoadingImposto(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recalculaImpostoDelayed = useCallback(
    debounce(
      (
        prod_state: NotaDeSaidaItensSerializer,
        emitente: number,
        imposto_obj: ImpostoSerializer
      ) => recalculaImposto(prod_state, emitente, imposto_obj),
      1000
    ),
    [] // será criada apenas uma vez inicialmente
  );

  const typeToFormat = (type: string, value: number) => {
    switch (type) {
      case "R":
        return value.toBRL();
      case "P":
        return `${value.toDecimal()}%`;
      default:
        return value.toString();
    }
  };

  const getImpostoValues = () => {
    const obj: {
      nome: string;
      valor: number;
      valor_formatado: string;
    }[] = [];
    if (!impostoPreview) return obj;
    Object.keys(impostoPreview).forEach((key) => {
      if (
        CAMPOS_IMPOSTO_TO_VALID_NOME[
          key as keyof typeof CAMPOS_IMPOSTO_TO_VALID_NOME
        ]
      ) {
        obj.push({
          nome: CAMPOS_IMPOSTO_TO_VALID_NOME[
            key as keyof typeof CAMPOS_IMPOSTO_TO_VALID_NOME
          ].split(";")[0],
          valor: Number(impostoPreview[key as keyof typeof impostoPreview]),
          valor_formatado: typeToFormat(
            CAMPOS_IMPOSTO_TO_VALID_NOME[
              key as keyof typeof CAMPOS_IMPOSTO_TO_VALID_NOME
            ].split(";")[1],
            Number(impostoPreview[key as keyof typeof impostoPreview])
          ),
        });
      }
    });
    return obj;
  };

  const recalculaItem = async () => {
    const { subtotal, calc_desc, calc_acres, total } = totals();
    if (!prod) return;
    setStateNotaItem((_v) => {
      const final_obj = {
        ..._v,
        subtotal: subtotal,
        calc_desc: calc_desc,
        calc_acres: calc_acres,
        total: total,
      };
      if (!impostoObj || !stateNota.emitente) return final_obj;
      recalculaImpostoDelayed(
        final_obj,
        Number(stateNota.emitente),
        impostoObj
      );
      return final_obj;
    });
  };

  React.useEffect(() => {
    recalculaItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateNotaItem.preco_venda,
    stateNotaItem.quantidade,
    stateNotaItem.acrescimo,
    stateNotaItem.desconto,
    stateNotaItem.frete,
    stateNotaItem.produto,
    stateNotaItem.imposto,
    stateNotaItem.porcentagem_devolvida,
    impostoObj,
    prod,
  ]);

  const handleSave = () => {
    if (!stateNotaItem.produto) return handleSecModal(1);

    const itensTemp = [...stateNota.itens];

    const idx = itensTemp.findIndex((x) => x.produto === stateNotaItem.produto);
    if (idx > -1) {
      itensTemp[idx] = stateNotaItem;
    } else {
      itensTemp.push(stateNotaItem);
    }

    setStateNota((_v) => ({
      ..._v,
      itens: itensTemp,
      ...getTotaisNota(itensTemp, stateNota.pagamentos, listProds),
    }));
    onClose();
  };

  const onChangeProduto = (e: NuvelAutocompleteEvent) => {
    const idx = stateNota.itens.findIndex((x) => x.produto === e.target.value);
    if (idx > -1) {
      setStateNotaItem(stateNota.itens[idx]);
    } else {
      handleChange(e, setStateNotaItem);
    }
  };

  const saveDisabled = () => {
    return (
      !stateNotaItem.produto ||
      !stateNotaItem.quantidade ||
      !stateNotaItem.imposto ||
      !stateNotaItem.total ||
      !stateNotaItem.subtotal
    );
  };

  return (
    <Modal
      fullWidth
      minWidth="lg"
      maxWidth="lg"
      open={modalSec.id === 1 ? modalSec.open : false}
      title="Inclusão de Item"
      btnCancel={() => {
        onClose();
      }}
      btnSave={handleSave}
      btnSaveDisabled={saveDisabled()}
    >
      <Grid container>
        <Grid xs={12} sm={5} className={`${classes.esquerda} ${classes.comum}`}>
          <Grid container spacing={1}>
            <Grid xs={12} sm={12}>
              <NuvelAutocomplete
                label="Produto"
                name="produto"
                value={Number(stateNotaItem.produto)}
                onChange={(e, _select, fullValue) => {
                  onChangeProduto(e);
                  if (fullValue?.imposto) {
                    setStateNotaItem((_v) => ({
                      ..._v,
                      imposto: fullValue.imposto ?? "",
                    }));
                  } else {
                    setStateNotaItem((_v) => ({
                      ..._v,
                      imposto: "",
                      imposto_obj: undefined,
                    }));
                    setImpostoPreview(null);
                  }
                }}
                reference="produto"
              />
            </Grid>
            <Grid xs={11} sm={3}>
              <TextField
                fullWidth
                decimal
                min={0.0001}
                variant="filled"
                label="Quant."
                color="primary"
                name="quantidade"
                value={stateNotaItem.quantidade}
                onChange={(e) => handleChange(e, setStateNotaItem)}
              />
            </Grid>
            <Grid
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Cancel fontSize="small" />
            </Grid>
            <Grid xs={10} sm={8}>
              <TextField
                fullWidth
                variant="filled"
                label="Valor Unitário"
                color="primary"
                name="preco_venda"
                value={stateNotaItem.preco_venda}
                onChange={(e) => handleChange(e, setStateNotaItem)}
                money
              />
            </Grid>
            <EqualIcon />
            <Grid xs={10}>
              <TextField
                fullWidth
                variant="filled"
                readOnly
                label="Subtotal"
                color="primary"
                name="subtotal"
                value={stateNotaItem.subtotal}
                money
              />
            </Grid>
            <MinusIcon />
            <Grid xs={3}>
              <TextField
                fullWidth
                variant="filled"
                perc
                label="Desconto"
                color="primary"
                name="desconto"
                value={stateNotaItem.desconto}
                onChange={(e) => handleChange(e, setStateNotaItem)}
              />
            </Grid>
            <Grid xs={7}>
              <TextField
                fullWidth
                variant="filled"
                readOnly
                label="Total Desconto"
                color="primary"
                name="calc_desc"
                value={stateNotaItem.calc_desc}
              />
            </Grid>
            <PlusIcon />
            <Grid xs={3}>
              <TextField
                fullWidth
                variant="filled"
                perc
                label="Acréscimo"
                color="primary"
                name="acrescimo"
                value={stateNotaItem.acrescimo}
                onChange={(e) => handleChange(e, setStateNotaItem)}
              />
            </Grid>
            <Grid xs={7}>
              <TextField
                fullWidth
                variant="filled"
                readOnly
                label="Total Acréscimo"
                color="primary"
                name="calc_acres"
                value={stateNotaItem.calc_acres}
              />
            </Grid>
            <PlusIcon />
            <Grid xs={10}>
              <TextField
                fullWidth
                variant="filled"
                money
                label="Frete"
                color="primary"
                name="frete"
                value={stateNotaItem.frete}
                onChange={(e) => handleChange(e, setStateNotaItem)}
              />
            </Grid>
            <EqualIcon />
            <Grid xs={10}>
              <TextField
                fullWidth
                variant="filled"
                money
                readOnly
                label="Total"
                color="primary"
                name="total"
                value={stateNotaItem.total}
                onChange={(e) => handleChange(e, setStateNotaItem)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={7} className={`${classes.direita} ${classes.comum}`}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <NuvelAutocomplete
                label="Imposto"
                name="imposto"
                value={stateNotaItem.imposto}
                onChange={(e) => handleChange(e, setStateNotaItem)}
                reference="imposto"
                onFetchCurrentObject={(imposto_obj) => {
                  setStateNotaItem((_v) => ({
                    ..._v,
                    imposto_obj,
                  }));
                }}
              />
            </Grid>

            <Grid xs={6}>
              <Select
                label="CST"
                name="cst"
                disabled
                value={impostoObj?.cst || ""}
                onChange={(e) => handleChange(e, setStateNotaItem)}
                options={CST_LIST}
              />
            </Grid>
            <Grid xs={3}>
              <TextField
                disabled
                fullWidth
                variant="filled"
                label="CFOP"
                color="primary"
                name="cfop"
                value={impostoObj?.cfop || ""}
                onChange={(e) => handleChange(e, setStateNotaItem)}
              />
            </Grid>
            <Grid xs={3}>
              <Select
                disabled
                label="CSOSN"
                name="csosn"
                value={impostoObj?.csosn || ""}
                onChange={(e) => handleChange(e, setStateNotaItem)}
                options={CSOSN}
              />
            </Grid>
            {stateNota.finalidade ===
              NotaDeSaidaSerializerFinalidadeEnum["Devolução/Retorno"] && (
              <Grid xs={5}>
                <TextField
                  fullWidth
                  variant="filled"
                  perc
                  label="Perc. Devolvida"
                  color="primary"
                  name="porcentagem_devolvida"
                  value={stateNotaItem.porcentagem_devolvida}
                  onChange={(e) => handleChange(e, setStateNotaItem)}
                />
              </Grid>
            )}
            <Grid xs={12}>
              <Divider />
            </Grid>
            {loadingImposto ? (
              <Grid
                xs={12}
                style={{
                  textAlign: "center",
                  marginTop: "20%",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={64} />
              </Grid>
            ) : (
              <Grid xs={12}>
                <Typography style={{ padding: 2 }} variant="body2">
                  Pré-visualização de impostos (Considerar como 0 caso não
                  exiba)
                </Typography>
                {impostoPreview?.error ? (
                  <Typography style={{ padding: 2 }} variant="body1">
                    {impostoPreview.error}
                  </Typography>
                ) : (
                  getImpostoValues()
                    .filter((x) => Number(x.valor) > 0)
                    .map((x, i) => (
                      <Typography
                        style={{ padding: 2 }}
                        variant="body1"
                        key={i}
                      >
                        {x.nome}: {x.valor_formatado}
                      </Typography>
                    ))
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default InclusaoDeItem;
