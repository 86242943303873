import { Box, Button } from "@mui/material";
import TabPanel from "components/nuvel/TabPanel";
import TextField from "components/nuvel/TextField";
import { NotaDeSaidaSerializer } from "data/interfaces/financeiro/NotaDeSaidaSerializer";

interface NotasFiscaisReferenciadasTabProps {
  tabs: number;
  stateNota: NotaDeSaidaSerializer;
  setStateNota: React.Dispatch<React.SetStateAction<NotaDeSaidaSerializer>>;
}

const NotasFiscaisReferenciadasTab = ({
  tabs,
  stateNota,
  setStateNota,
}: NotasFiscaisReferenciadasTabProps) => {
  return (
    <TabPanel value={tabs} index={4}>
      {stateNota.notas_fiscais_referenciadas.split(",").map((v, i) => (
        <Box
          key={"notas_fiscais_referenciadas_" + i}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginTop: 24, marginBottom: 24 }}
        >
          <TextField
            label="Chaves de acesso referenciadas"
            name="notas_fiscais_referenciadas"
            value={v}
            onChange={(e) => {
              const _temp = [
                ...stateNota.notas_fiscais_referenciadas.split(","),
              ];
              _temp[i] = e.target.value;
              setStateNota((v) => ({
                ...v,
                notas_fiscais_referenciadas: _temp.join(","),
              }));
            }}
          />
          <Button
            style={{ marginLeft: 8 }}
            color="secondary"
            onClick={() => {
              const _temp = [
                ...stateNota.notas_fiscais_referenciadas.split(","),
              ];
              _temp.splice(i, 1);
              setStateNota((v) => ({
                ...v,
                notas_fiscais_referenciadas: _temp.join(","),
              }));
            }}
          >
            Remover
          </Button>
        </Box>
      ))}
      <Button
        color="secondary"
        onClick={() => {
          setStateNota((v) => ({
            ...v,
            notas_fiscais_referenciadas: v.notas_fiscais_referenciadas + ",",
          }));
        }}
      >
        Adicionar
      </Button>
    </TabPanel>
  );
};

export default NotasFiscaisReferenciadasTab;
