import { NuvelDefaultList } from "components/nuvel/lists";

import {
  RETAGUARDA_REPORT_REQUEST_FILTERS,
  RETAGUARDA_REPORT_REQUEST_LIST,
  RETAGUARDA_REPORT_REQUEST_ORDERING,
} from "constants/retaguarda/relatorio/requests";

import { ReportRequestModel } from "data/models";
import React from "react";

const RetReportRequestList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={ReportRequestModel}
      columns={RETAGUARDA_REPORT_REQUEST_LIST}
      filters={RETAGUARDA_REPORT_REQUEST_FILTERS}
      ordering={RETAGUARDA_REPORT_REQUEST_ORDERING}
      actions={{
        create: false,
        update: false,
        delete: false,
      }}
    />
  );
};

export default RetReportRequestList;
