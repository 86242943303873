import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NuvelCheckbox from "components/nuvel/Checkbox";
import { NuvelPaginationComponent } from "components/nuvel/Pagination";
import { useState } from "react";
import InputField from "./inputField";

/* eslint-disable @typescript-eslint/no-explicit-any */
const BFTable = (props: any) => {
  const {
    onRowClick,
    columns,
    rows,
    MobileComponent,
    noPaper,
    child,
    checkbox,
    setRows,
    cellStyle,
    headerStyle,
    father,
    pageCount,
    page,
    setPage,
    border,
  } = props;
  const theme = useTheme();
  const [expanded, setExpanded] = useState<any>([]);
  const isMobile = useMediaQuery("(max-width: 480px)");
  const checkBoxState = checkbox?.state || [];

  function MobileTable(props: any) {
    return isMobile ? props.children : <></>;
  }

  function filterMobileData(row: any, columns: any) {
    const data = columns
      .filter((col: any) => !col.onlyView)
      .map((col: any, k: any) => ({
        id: [col.accessor],
        value:
          typeof col.accessor === "function"
            ? col.accessor(row, k)
            : row[col.accessor],
      }));
    const mapped = data.map((item: any) => ({ [item.id]: item.value }));
    const newObj = Object.assign({}, ...mapped);
    return { ...row, ...newObj };
  }

  function isExpanded(index: any) {
    return expanded?.find((w: any) => w === Number(index)) >= 0 ? true : false;
  }

  function toggleExpanded(index: any) {
    if (isExpanded(index)) {
      setExpanded(expanded.filter((w: any) => w !== index));
    } else {
      setExpanded([...(expanded || []), index]);
    }
  }

  const isSelected = (id: any) =>
    checkBoxState.find((x: any) => x.id === id) ? true : false;

  const onSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const _temp = [...checkBoxState];
      const newSelecteds = rows.map((n: any) => n);
      const _temp2 = [..._temp, ...newSelecteds].filter(
        (v, i, a) =>
          a.findIndex(
            (t) =>
              t[checkbox.key ? checkbox.key : "id"] ===
              v[checkbox.key ? checkbox.key : "id"]
          ) === i
      );
      checkbox.setState(_temp2);
      return;
    }
    checkbox.setState([]);
  };
  const handleCheck = (_: any, row: any) => {
    const _temp = [...checkBoxState];
    const index = _temp.findIndex(
      (x) =>
        x[checkbox.key ? checkbox.key : "id"] ===
        row[checkbox.key ? checkbox.key : "id"]
    );
    if (index !== -1) {
      _temp.splice(index, 1);
    } else {
      _temp.push(row);
    }
    checkbox.setState([..._temp]);
  };

  return (
    <>
      {!isMobile && (
        <>
          <TableContainer
            component={noPaper ? "div" : Paper}
            style={{
              paddingLeft: (props.childLevel || 0) * 60,
              backgroundColor: props.childLevel > 0 ? "#F5F5F5" : undefined,
              border: border
                ? `1px solid ${theme.palette.tertiary.main}`
                : undefined,
              borderRadius: "6px",
              height: border ? "100%" : undefined,
              color:
                localStorage.getItem("theme") === "light" ? "#333" : "#fff",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              scrollbarWidth: "thin",
              scrollbarColor:
                "linear-gradient(to bottom, rgba(1, 138, 218, 1), rgba(1, 138, 218, 1)) rgba(0, 0, 0, .55)",
            }}
            sx={{
              "&::-webkit-scrollbar": {
                height: "3px",
                width: "20px",
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "rgb(173, 172, 168)",
              },
              "&::-webkit-scrollbar-thumb": {
                background:
                  "linear-gradient(to bottom, rgba(1, 138, 218, 1), rgba(1, 138, 218, 1))",
                // border: "3px solid orange",
              },
            }}
          >
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  {child && (
                    <TableCell
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#333"
                            : "#fff",
                      }}
                      padding="checkbox"
                    />
                  )}
                  {checkbox ? (
                    <TableCell
                      style={{
                        color:
                          localStorage.getItem("theme") === "light"
                            ? "#333"
                            : "#fff",
                      }}
                      padding="checkbox"
                    >
                      <NuvelCheckbox
                        indeterminate={
                          checkBoxState.length > 0 &&
                          checkBoxState.length < rows.length
                        }
                        checked={
                          rows.length > 0 &&
                          checkBoxState.length === rows.length
                        }
                        onChange={onSelectAllClick}
                      />
                    </TableCell>
                  ) : null}
                  {columns?.map((col: any, k: any) => {
                    if (col.renderHeader) return col.renderHeader(col);
                    return (
                      <TableCell
                        key={k}
                        style={{
                          ...headerStyle,
                          ...col.hstyle,
                          width: col.width,
                          color:
                            localStorage.getItem("theme") === "light"
                              ? "#333"
                              : "#fff",
                        }}
                      >
                        {typeof col.Header === "function"
                          ? col.Header()
                          : col.Header || col.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows || []).map((row: any, krow: any) => {
                  const isItemSelected = isSelected(row.id);
                  // const ActionsComponent = row.actions;

                  return (
                    <>
                      <TableRow
                        hover
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onRowClick) onRowClick(row, krow);
                        }}
                        key={krow}
                      >
                        {child && (
                          <TableCell
                            style={{
                              borderBottom: "none",
                              width: 5,
                              color:
                                localStorage.getItem("theme") === "light"
                                  ? "#333"
                                  : "#fff",
                            }}
                          >
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleExpanded(krow);
                              }}
                              style={{ margin: 0, padding: 5 }}
                              size="large"
                            >
                              {isExpanded(krow) ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </IconButton>
                          </TableCell>
                        )}

                        {checkbox ? (
                          <TableCell
                            style={{
                              color:
                                localStorage.getItem("theme") === "light"
                                  ? "#333"
                                  : "#fff",
                            }}
                            padding="checkbox"
                          >
                            <NuvelCheckbox
                              checked={isItemSelected}
                              onChange={(event) => handleCheck(event, row)}
                            />
                          </TableCell>
                        ) : null}
                        {columns.map((col: any, kcol: any) => (
                          <TableCell
                            key={kcol}
                            style={{
                              ...cellStyle,
                              ...col.style,
                              maxWidth: col.maxWidth,
                              borderBottomStyle: child ? "none" : "solid",
                              color:
                                localStorage.getItem("theme") === "light"
                                  ? "#333"
                                  : "#fff",
                            }}
                            width={col.width}
                            align={col.align}
                          >
                            {col.input ? (
                              typeof col.input.showInput === "function" &&
                                !col.input?.showInput(row) ? (
                                typeof col.accessor === "function" ? (
                                  col.accessor(row, kcol, krow)
                                ) : (
                                  row[col.accessor]
                                )
                              ) : (
                                <InputField
                                  money={col.input.money}
                                  perc={col.input.perc}
                                  type={col.input.type || "text"}
                                  inputProps={{
                                    key: [col.accessor, krow, kcol].join("_"),
                                    id: [col.accessor, krow, kcol].join("_"),
                                    autofocus: true,
                                  }}
                                  onChange={(e: any) => {
                                    if (col.input.onChange) {
                                      col.input.onChange(
                                        e.target.value,
                                        Number(krow),
                                        Number(kcol)
                                      );
                                    } else {
                                      const newRows = rows;
                                      newRows[Number(krow)][
                                        typeof col.accessor === "function"
                                          ? col.accessor(row)
                                          : col.accessor
                                      ] = e.target.value;
                                      setRows(newRows);
                                    }
                                  }}
                                  name={col.accessor}
                                  value={row[col.accessor]}
                                />
                              )
                            ) : typeof col.accessor === "function" ? (
                              col.accessor(row, kcol, krow, father)
                            ) : (
                              row[col.accessor]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      {child && (
                        <TableRow>
                          <TableCell
                            colSpan={Number(columns.length + 1)}
                            style={{
                              padding: 0,
                              color:
                                localStorage.getItem("theme") === "light"
                                  ? "#333"
                                  : "#fff",
                            }}
                          >
                            <Collapse in={isExpanded(krow)}>
                              <BFTable
                                {...child}
                                rows={
                                  child?.field
                                    ? row[child?.field]
                                    : child.rows(row)
                                }
                                father={row}
                                childLevel={(props.childLevel || 0) + 1}
                                columns={child?.columns}
                                noPaper
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {page ? (
            <Box display="flex" mt={1} style={{ width: "100%" }}>
              <NuvelPaginationComponent
                setPage={setPage}
                page={page}
                count={pageCount}
                pageSize={10}
              />
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
      <MobileTable>
        {MobileComponent ? (
          <div>
            {rows?.map((row: any, k: any) => (
              <MobileComponent
                key={k}
                onClick={() => (onRowClick ? onRowClick(row, k) : undefined)}
                data={filterMobileData(row, columns)}
              />
            ))}
          </div>
        ) : (
          "Não foi definida Renderização para mobile nesta table."
        )}
      </MobileTable>
    </>
  );
};

export default BFTable;
