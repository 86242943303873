import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CodBarraSerializer } from "data/interfaces/estoque/CodBarraSerializer";
import { GradeItemSerializer } from "data/interfaces/estoque/GradeItemSerializer";
import { GradeSerializer } from "data/interfaces/estoque/GradeSerializer";
import { ProdutoModel } from "data/models/estoque";
import { useMemo, useState } from "react";
import { showMessage } from "utils/functions/dialog";
import NuvelAutocomplete from "../Autocomplete";
import NuvelCheckbox from "../Checkbox";
import NuvelSelect from "../Select";
import NuvelTextField from "../TextField";

interface NuvelProductCodBarraDialogProps {
  open: boolean;
  onClose: () => void;
  codBarra: CodBarraSerializer | null;
  onChangeCodBarra: (codBarra: CodBarraSerializer) => void;
  onSave: () => void;
  grades: GradeSerializer[];
  checkExistingGradeItems: (
    gradeItems: GradeItemSerializer[]
  ) => CodBarraSerializer | undefined;
}

const NuvelProductCodBarraDialog = ({
  open,
  onClose,
  codBarra,
  onChangeCodBarra,
  onSave,
  grades,
  checkExistingGradeItems,
}: NuvelProductCodBarraDialogProps) => {
  const isNew = codBarra?.id === undefined;

  const title = isNew ? "Novo Código de Barras" : `Editando Código de Barras`;
  const [isLoading, setIsLoading] = useState(false);

  const btnSaveDisabled = useMemo(() => {
    const mainCheck =
      !codBarra?.cod_barra || !codBarra?.unidade || !codBarra?.fator;
    if (grades.length > 0) {
      const hasAllGradeItemsSet =
        codBarra?.grade_itens.length === grades.length;
      return mainCheck || !hasAllGradeItemsSet;
    }
    return mainCheck;
  }, [codBarra, grades]);

  const existingGradeItemsBarCode = useMemo(() => {
    if (grades.length === 0) return undefined;
    if (!codBarra) return undefined;
    const existing = checkExistingGradeItems(codBarra.grade_itens);
    if (!existing) return undefined;
    if (existing.id === codBarra?.id) return undefined;
    return existing;
  }, [codBarra, grades, checkExistingGradeItems]);

  const handleSave = () => {
    if (!codBarra?.cod_barra) return;
    setIsLoading(true);
    ProdutoModel.action("post", "verificar_cod_barras", {
      codbarra: codBarra?.cod_barra,
    })
      .then((res) => {
        if (res.data.cod_barras_id !== codBarra?.id) {
          // Different cod_barras_id, so we can't save
          showMessage(
            `O código de barras ${codBarra?.cod_barra} já está cadastrado no produto ${res.data.produto}`
          );
          setIsLoading(false);
          return;
        }
        // Same cod_barras_id, so we can save
        onSave();
        setIsLoading(false);
      })
      .catch(() => {
        // Not found, so we can save
        onSave();
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        dividers
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        <NuvelTextField
          label="Código de Barras"
          value={codBarra?.cod_barra}
          onChange={(e) =>
            onChangeCodBarra({ ...codBarra!, cod_barra: e.target.value })
          }
        />
        <NuvelAutocomplete
          label="Unidade"
          name="unidade"
          value={codBarra?.unidade}
          onChange={(_e, _v, fullValue) => {
            if (fullValue && fullValue.id) {
              onChangeCodBarra({
                ...codBarra!,
                unidade: fullValue.id,
                unidade_nome: fullValue.nome,
              });
            }
          }}
          reference="unidade"
        />
        <NuvelTextField
          label="Fator"
          value={codBarra?.fator}
          type="number"
          onChange={(e) =>
            onChangeCodBarra({ ...codBarra!, fator: Number(e.target.value) })
          }
        />
        <NuvelCheckbox
          label="Sem GTIN"
          checked={codBarra?.sem_gtin}
          onChange={(e) =>
            onChangeCodBarra({ ...codBarra!, sem_gtin: e.target.value })
          }
        />
        {grades.length > 0 &&
          grades.map((grade) => (
            <NuvelSelect
              key={grade.id}
              label={grade.descricao}
              name="grade"
              value={
                codBarra?.grade_itens.find(
                  (gradeItem) => gradeItem.grade === grade.id
                )?.id
              }
              options={grade.itens.map((item) => ({
                label: item.descricao,
                value: item.id,
              }))}
              onChange={(e) => {
                const item = grade.itens.find(
                  (item) => item.id === e.target.value
                );
                if (!item) return;
                const gradeItems = codBarra?.grade_itens.filter(
                  (gradeItem) => gradeItem.grade !== grade.id
                );
                onChangeCodBarra({
                  ...codBarra!,
                  grade_itens: [...(gradeItems || []), item],
                });
              }}
            />
          ))}
        <Collapse in={!!existingGradeItemsBarCode}>
          <Alert severity="warning">
            Essa combinação de grade já existe em outro código de barras:{" "}
            {existingGradeItemsBarCode?.cod_barra}
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={btnSaveDisabled || isLoading}
        >
          {isLoading ? "Salvando..." : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NuvelProductCodBarraDialog;
