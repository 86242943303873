/* eslint-disable @typescript-eslint/no-explicit-any */
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import DefaultRelatorios from "components/retaguarda/relatorios";
import dayjs from "dayjs";
import React from "react";
import { handleChange } from "utils/functions";

const status = [
  "Recebido",
  "Recebido",
  "Enviado Contingencia",
  "Cancelado",
  "Erro Sefaz",
  "Contingencia",
  "Cancelado sem finalizar",
];

interface VendasState {
  relatorio: number;
  data_inicio: dayjs.Dayjs;
  data_fim: dayjs.Dayjs;
  cliente: number | undefined;
  vendedor: number | undefined;
  cstat: number;
  lojas: number[];
  tags: number[];
  produtos: number[];
}

const filtros = (state: VendasState) => ({
  data_inicio: dayjs(state.data_inicio).format(),
  data_fim: dayjs(state.data_fim).format(),
  lojas: state.lojas.join(","),
  cstat: state.cstat === 0 ? undefined : state.cstat,
  tags: state.tags.join(","),
  vendedor: state.vendedor || undefined,
  cliente: state.cliente || undefined,
  produtos: state.produtos.join(",") || undefined,
});

const defaultUrl = "vendas";

const relatorios = (state: VendasState) => [
  {
    url: `${defaultUrl}/sintetico`,
    title: "Relatório de Vendas Sintético",
    columns: [
      { label: "Cupom", accessor: "cupom", flex: 2 },
      {
        label: "Data",
        accessor: "data_fechamento",
        flex: 2,
        type: "datetime",
      },
      { label: "Cliente", accessor: "cliente", flex: 2 },
      { label: "PDV", accessor: "pdv", flex: 2 },
      { label: "Loja", accessor: "loja", flex: 2 },
      { label: "Vendedor", accessor: "vendedor", flex: 2 },
      { label: "Desconto", accessor: "desconto", flex: 2, type: "money" },
      { label: "Acréscimo", accessor: "acrescimo", flex: 2, type: "money" },
      { label: "Total", accessor: "total", flex: 2, type: "money" },
    ],
    filtros: filtros(state),
    totais: "desconto,acrescimo,total",
  },
  {
    url: `${defaultUrl}/analitico`,
    title: "Relatório de Vendas Analítico",
    columns: [
      { label: "Cupom", accessor: "cupom", flex: 2 },
      {
        label: "Data",
        accessor: "data_fechamento",
        flex: 2,
        type: "datetime",
      },
      { label: "Cliente", accessor: "cliente", flex: 2 },
      { label: "Loja", accessor: "loja", flex: 2 },
      { label: "Vendedor", accessor: "vendedor", flex: 2 },
      { label: "Desconto", accessor: "desconto", flex: 2, type: "money" },
      { label: "Acréscimo", accessor: "acrescimo", flex: 2, type: "money" },
      { label: "Total", accessor: "total", flex: 2, type: "money" },
    ],
    childs: [
      {
        label: "Itens",
        key: "nf_items",
        columns: [
          { label: "Código", accessor: "produto__codigo", flex: 2 },
          { label: "Produto", accessor: "produto__nome", flex: 2 },
          { label: "Qnt", accessor: "quantidade", flex: 2 },
          { label: "Unidade", accessor: "cod_barra__unidade__nome", flex: 2 },
          { label: "V. Unit", accessor: "v_unitario", type: "money", flex: 2 },
          { label: "V. Total", accessor: "v_total", type: "money", flex: 2 },
        ],
      },
      {
        label: "Pagamentos",
        key: "cupom_pagamento",
        columns: [
          { label: "Forma Pagamento", accessor: "forma__forma", flex: 2 },
          { label: "Valor", accessor: "total", flex: 2, type: "money" },
        ],
      },
    ],
    filtros: filtros(state),
    totais: "desconto,acrescimo,total",
  },
  {
    url: `${defaultUrl}/vendas_de_produtos`,
    title: "Relatório de Vendas de Produtos",
    columns: [
      { label: "Cod.Barra", accessor: "cod_barra", flex: 1 },
      { label: "Produto", accessor: "produto", flex: 1 },
      { label: "Loja", accessor: "loja", flex: 2 },
      { label: "Preço Un.", accessor: "preco", flex: 1, type: "money" },
      { label: "Quantidade", accessor: "quantidade", flex: 1 },
      { label: "Total", accessor: "total", flex: 1, type: "money" },
    ],
    filtros: filtros(state),
    totais: "preco,quantidade,total",
  },
  {
    url: `${defaultUrl}/lucratividade`,
    title: "Relatório de Lucratividade",
    columns: [
      { label: "Cod.Barra", accessor: "cod_barra", flex: 1 },
      { label: "Produto", accessor: "produto", flex: 1 },
      {
        label: "Preço de Custo",
        accessor: "preco_custo",
        flex: 1,
        type: "money",
      },
      { label: "Preço Un.", accessor: "preco", flex: 1, type: "money" },
      {
        label: "Lucratividade",
        accessor: "lucratividade",
        flex: 1,
        type: "percent",
      },
    ],
    filtros: filtros(state),
    totais: "lucratividade",
  },
  {
    url: `${defaultUrl}/vendas_por_loja`,
    title: "Relatório de Vendas por Loja",
    columns: [
      { label: "Razão Social", accessor: "razao_social", flex: 1 },
      { label: "Referencia", accessor: "referencia", flex: 1 },
      { label: "Total", accessor: "total", flex: 1, type: "money" },
    ],
    filtros: filtros(state),
    totais: "total",
  },
  {
    url: `${defaultUrl}/cupom`,
    title: "Relatório de Cupons",
    columns: [
      { label: "Cupom", accessor: "cupom", flex: 2 },
      {
        label: "Status",
        accessor: (row: any) => status[row.status],
        flex: 2,
      },
      { label: "Emitente", accessor: "emitente", flex: 2 },
      {
        label: "Data Fecham.",
        accessor: "data_fechamento",
        flex: 2,
        type: "datetime",
      },
      { label: "Cliente", accessor: "cliente", flex: 2 },
      { label: "Desconto", accessor: "desconto", flex: 2, type: "money" },
      { label: "Acréscimo", accessor: "acrescimo", flex: 2, type: "money" },
      { label: "Total", accessor: "total", flex: 2, type: "money" },
    ],
    filtros: filtros(state),
  },
  {
    url: `${defaultUrl}/vendedor`,
    title: "Relatório de total de vendas por Vendedor",
    columns: [
      { label: "Vendedor", accessor: "vendedor", flex: 2 },
      { label: "Quantidade", accessor: "quantidade", flex: 2 },
      { label: "Desconto", accessor: "desconto", flex: 2, type: "money" },
      { label: "Acréscimo", accessor: "acrescimo", flex: 2, type: "money" },
      { label: "Total", accessor: "total", flex: 2, type: "money" },
    ],
    filtros: filtros(state),
    totais: "total",
  },
  {
    url: `${defaultUrl}/curva_abc`,
    title: "Curva ABC",
    columns: [
      { label: "Produto", accessor: "produto", flex: 3 },
      {
        label: "Porcentagem",
        accessor: "percent_rank",
        type: "percent",
        flex: 1,
      },
      {
        label: "Classificação",
        accessor: "categoria",
        flex: 1,
      },
    ],
    filtros: filtros(state),
  },
];

const RetReportVendasForm: React.FC = () => {
  const [state, setState] = React.useState<VendasState>({
    relatorio: 0,
    data_inicio: dayjs().startOf("day"),
    data_fim: dayjs().endOf("day"),
    cliente: undefined,
    vendedor: undefined,
    cstat: 100,
    lojas: [],
    tags: [],
    produtos: [],
  });

  return (
    <DefaultRelatorios
      title="Vendas"
      relatorio={state.relatorio}
      relatorios={relatorios(state)}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={4}>
          <NuvelSelect
            label="Relatório"
            name="relatorio"
            onChange={(e) => handleChange(e, setState)}
            value={state.relatorio}
            options={relatorios(state).map((rel, index) => ({
              value: index,
              label: rel.title,
            }))}
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelDatePicker
            dateTime
            label="Data Início"
            onChange={(e) => handleChange(e, setState)}
            name="data_inicio"
            value={state.data_inicio}
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelDatePicker
            dateTime
            label="Data Fim"
            onChange={(e) => handleChange(e, setState)}
            name="data_fim"
            value={state.data_fim}
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelSelect
            label="Situação"
            name="cstat"
            onChange={(e) => handleChange(e, setState)}
            value={state.cstat}
            options={[
              { label: "Todas", value: 0 },
              { label: "Ativas", value: 100 },
              { label: "Canceladas", value: 101 },
            ]}
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelAutocomplete
            multiple
            label="Tags"
            name="tags"
            reference="tags"
            value={state.tags}
            onChange={(_, v) => setState({ ...state, tags: v as number[] })}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelAutocomplete
            label="Cliente"
            name="cliente"
            value={state.cliente}
            reference="cliente"
            onChange={(_, v) => setState({ ...state, cliente: v as number })}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelAutocomplete
            label="Vendedor"
            name="vendedor"
            reference="funcionario"
            value={state.vendedor}
            onChange={(_, v) => setState({ ...state, vendedor: v as number })}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelAutocomplete
            multiple
            label="Lojas"
            name="lojas"
            reference="loja"
            value={state.lojas}
            onChange={(_, v) => setState({ ...state, lojas: v as number[] })}
          />
        </NuvelGrid>
        {state.relatorio === 2 && (
          <NuvelGrid xs={4}>
            <NuvelAutocomplete
              label="Produto"
              name="produtos"
              value={state.produtos}
              onChange={(_, v) =>
                setState({ ...state, produtos: v as number[] })
              }
              reference="produto"
              multiple
            />
          </NuvelGrid>
        )}
      </NuvelGrid>
    </DefaultRelatorios>
  );
};

export default RetReportVendasForm;
