import React, { useState, ChangeEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  PaperProps,
} from "@mui/material";
import NuvelSelect from "./Select";
import NuvelTextField from "./TextField";

interface GlobalDialogProps {
  open: boolean;
  title: string;
  message: string;
  type: number;
  isTouch?: boolean;
  setConfirm?: (confirm: boolean | string) => void;
  onCancel?: () => void;
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputProps?: any;
}

const GlobalDialog: React.FC<GlobalDialogProps> = (props) => {
  const [text, setText] = useState<string>("");

  const handleClose = () => {
    if (props.onCancel) {
      props.onCancel();
    }
    if (props.setConfirm) {
      if (props.type !== 2) {
        props.setConfirm(false);
      }
      setText("");
    }
    props.handleClose();
  };

  const handleConfirm = () => {
    if (props.setConfirm) {
      if (props.type === 0 || props.type === 1) {
        props.setConfirm(true);
      }
      if (props.type === 2 || props.type === 3) {
        props.setConfirm(text);
      }
      setText("");
    }
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open || false}
      PaperComponent={Paper as React.ComponentType<PaperProps>}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message && props.message.includes("\n") ? (
            <>
              {props.message.split("\n").map((item, index) => (
                <b key={index}>{item}</b>
              ))}
            </>
          ) : (
            <b>{props.message}</b>
          )}
        </DialogContentText>
        {props.type === 2 ? (
          <NuvelTextField
            {...props.inputProps}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setText(e.target.value)
            }
            value={text}
            fullWidth
            style={{ marginTop: 10 }}
          />
        ) : null}
        {props.type === 3 ? (
          <NuvelSelect
            {...props.inputProps}
            onChange={(e) => setText(e.target.value)}
            value={text}
            fullWidth
            style={{ marginTop: 10 }}
            options={[
              { value: 1, label: "Retaguarda" },
              { value: 2, label: "Suporte" },
              { value: 3, label: "Administração" },
            ]}
          />
        ) : null}
      </DialogContent>
      <DialogActions
        style={props.isTouch ? { justifyContent: "space-between" } : undefined}
      >
        {[2, 0, 3].indexOf(props.type) > -1 ? (
          <Button
            onClick={handleConfirm}
            color="primary"
            autoFocus
            data-cy="btn-dialog-ok"
            variant={props.isTouch ? "contained" : "text"}
            sx={props.isTouch ? { fontSize: 20 } : undefined}
          >
            Ok
          </Button>
        ) : (
          <>
            <Button
              onClick={handleClose}
              color="primary"
              data-cy="btn-dialog-nao"
              variant={props.isTouch ? "contained" : "text"}
              sx={props.isTouch ? { fontSize: 20 } : undefined}
            >
              Não
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              autoFocus
              data-cy="btn-dialog-sim"
              variant={props.isTouch ? "contained" : "text"}
              sx={props.isTouch ? { fontSize: 20 } : undefined}
            >
              Sim
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalDialog;
