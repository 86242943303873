import { RouteInterface } from "utils/router";

import RetPDVForm from "../views/cadastro/pdv/form";
import RetPDVList from "../views/cadastro/pdv/list";
import RetPDVCaixaList from "../views/pdv/caixa/list";

const pdvRoutes: RouteInterface[] = [
  {
    title: "PDV",
    path: "/retaguarda/pdv/pdv",
    element: <RetPDVList />,
    form: <RetPDVForm />,
    perm: "CADASTRO_PDV",
  },
  {
    title: "Caixa",
    path: "/retaguarda/pdv/caixa",
    element: <RetPDVCaixaList />,
    perm: "PDV_CAIXA",
  },
];

export default pdvRoutes;
