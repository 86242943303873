import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import DefaultRelatorios from "components/retaguarda/relatorios";
import dayjs from "dayjs";
import React from "react";
import { handleChange } from "utils/functions";

interface LojasState {
  relatorio: number;
  data_inicio: dayjs.Dayjs;
  data_fim: dayjs.Dayjs;
  lojas: number[];
}

const relatorios = () => [
  {
    url: "lojas/desativadas",
    title: "Lojas Desativadas",
    columns: [
      { label: "ID", accessor: "id", flex: 1 },
      { label: "Nome", accessor: "razao_social", flex: 1 },
      { label: "CNPJ", accessor: "cnpj", flex: 2 },
    ],
    filtros: {},
  },
];

const RetReportLojasForm: React.FC = () => {
  const [state, setState] = React.useState<LojasState>({
    relatorio: 0,
    data_inicio: dayjs().startOf("day"),
    data_fim: dayjs().startOf("day"),
    lojas: [],
  });

  return (
    <DefaultRelatorios
      title="Lojas"
      relatorio={state.relatorio}
      relatorios={relatorios()}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12}>
          <NuvelSelect
            label="Relatório"
            name="relatorio"
            onChange={(e) => handleChange(e, setState)}
            value={state.relatorio}
            options={relatorios().map((rel, index) => ({
              value: index,
              label: rel.title,
            }))}
          />
        </NuvelGrid>
        {state.relatorio === 1 && (
          <>
            <NuvelGrid xs={12}>
              <NuvelAutocomplete
                multiple
                label="Lojas"
                name="lojas"
                reference="loja"
                value={state.lojas}
                onChange={(_, v) =>
                  setState({ ...state, lojas: v as number[] })
                }
              />
            </NuvelGrid>
            <NuvelGrid xs={6}>
              <NuvelDatePicker
                label="Data Início"
                onChange={(e) => handleChange(e, setState)}
                name="data_inicio"
                value={state.data_inicio}
              />
            </NuvelGrid>
            <NuvelGrid xs={6}>
              <NuvelDatePicker
                label="Data Fim"
                onChange={(e) => handleChange(e, setState)}
                name="data_fim"
                value={state.data_fim}
              />
            </NuvelGrid>
          </>
        )}
      </NuvelGrid>
    </DefaultRelatorios>
  );
};

export default RetReportLojasForm;
