// class ReportRequestViewSet(CoreModelViewSet):
//     queryset = ReportRequest.objects.all()
//     serializer_class = ReportRequestSerializer
//     filterset_fields = ("title", "tipo", "model", "status", "created_at", "request_by")
//     search_fields = ("title", "tipo", "model", "status", "created_at", "request_by")
//     ordering = "-created_at"

import { TableColumn, TableFilter } from "components/nuvel/lists";
import {
  ReportRequestSerializer,
  ReportRequestStatus,
} from "data/interfaces/relatorio/ReportRequestSerializer";
import { downloadArquivo } from "utils/functions/downloadFile";

function reportRequestStatusLabel(status: ReportRequestStatus) {
  switch (status) {
    case ReportRequestStatus.PENDING:
      return "Pendente";
    case ReportRequestStatus.PROCESSING:
      return "Processando";
    case ReportRequestStatus.PROCESSING_DOCUMENT:
      return "Processando Documento";
    case ReportRequestStatus.COMPLETED:
      return "Concluído";
    case ReportRequestStatus.ERROR:
      return "Erro";
    default:
      return "Desconhecido";
  }
}

function reportRequestStatusChipProps(status: ReportRequestStatus) {
  switch (status) {
    case ReportRequestStatus.PENDING:
      return {
        color: "primary" as const,
        style: { color: "white" },
      };
    case ReportRequestStatus.PROCESSING:
      return {
        color: "warning" as const,
      };
    case ReportRequestStatus.PROCESSING_DOCUMENT:
      return {
        color: "warning" as const,
      };
    case ReportRequestStatus.COMPLETED:
      return {
        color: "success" as const,
      };
    case ReportRequestStatus.ERROR:
      return {
        color: "error" as const,
      };
    default:
      return {
        color: "default" as const,
      };
  }
}

export const RETAGUARDA_REPORT_REQUEST_LIST: TableColumn<ReportRequestSerializer>[] =
  [
    {
      label: "Titulo",
      accessor: "title",
    },
    {
      label: "Status",
      accessor: (row) => reportRequestStatusLabel(row.status),
      type: "number",
      component: "chip",
      componentProps: (row) => reportRequestStatusChipProps(row.status),
    },
    {
      label: "Total",
      accessor: "total_records",
    },

    {
      label: "Processados",
      accessor: "total_processed",
    },
    {
      label: "Criado em",
      accessor: "created_at",
      type: "datetime",
    },
    {
      label: "Download",
      accessor: () => "Download",
      component: "download",
      componentProps: (row) => ({
        onClick: () =>
          new Promise((resolve) => {
            downloadArquivo(row.file, resolve);
          }),
        disabled: row.status !== ReportRequestStatus.COMPLETED,
      }),
    },
  ];

export const RETAGUARDA_REPORT_REQUEST_FILTERS: TableFilter<ReportRequestSerializer>[] =
  [
    {
      label: "Titulo",
      accessor: "title",
    },
    {
      label: "Tipo",
      accessor: "tipo",
    },
    {
      label: "Status",
      accessor: "status",
    },
    {
      label: "Criado em",
      accessor: "created_at",
    },
    {
      label: "Solicitado por",
      accessor: "request_by",
    },
  ];

export const RETAGUARDA_REPORT_REQUEST_ORDERING: (keyof ReportRequestSerializer)[] =
  ["created_at"];
