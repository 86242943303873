import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import { CodBarraSerializer } from "data/interfaces/estoque/CodBarraSerializer";
import { GradeSerializer } from "data/interfaces/estoque/GradeSerializer";
import { TransitionGroup } from "react-transition-group";
import { showConfirmSync } from "utils/functions/dialog";
interface NuvelCodBarraSelectProps {
  codbarras: CodBarraSerializer[];
  onChangeCodBarra: (value: CodBarraSerializer[]) => void;
  grades: GradeSerializer[];
  onSelectCodBarra: (
    codBarra: CodBarraSerializer,
    isNew: boolean,
    index: number
  ) => void;
}

const NuvelProductCodBarraList = ({
  codbarras,
  onChangeCodBarra,
  onSelectCodBarra,
}: NuvelCodBarraSelectProps) => {
  const handleDeleteCodBarra = async (index: number) => {
    const confirmed = await showConfirmSync(
      `Tem certeza que deseja remover este código de barras: ${codbarras[index].cod_barra}?`,
      "Remover Código de Barras"
    );
    if (confirmed) {
      onChangeCodBarra(codbarras.filter((_, i) => i !== index));
    }
  };

  const handleAddCodBarra = () => {
    const newCodBarra = {
      id: undefined,
      codigo: 0,
      cod_barra: "",
      grade_itens: [],
      unidade: 0,
      unidade_nome: "",
      fator: 1,
      sem_gtin: false,
      produto_nome: "",
      insumos: [],
      estoques: [],
    };
    onSelectCodBarra(newCodBarra, true, codbarras.length);
    onChangeCodBarra([...codbarras, newCodBarra]);
  };

  return (
    <Box sx={{ pt: 1, pb: 1 }}>
      <List
        subheader={
          <ListSubheader component="div" sx={{ bgcolor: "background.paper" }}>
            Códigos de Barras
          </ListSubheader>
        }
        sx={{ width: "100%", bgcolor: "background.paper", borderRadius: 2 }}
      >
        <TransitionGroup>
          {codbarras.map((codbarra, index) => (
            <Collapse key={codbarra.cod_barra}>
              <ListItem disablePadding key={codbarra.id} divider>
                <ListItemButton
                  onClick={() => onSelectCodBarra(codbarra, false, index)}
                >
                  <ListItemAvatar>
                    <Typography variant="h6" color="text.secondary">
                      {codbarra.unidade_nome}
                    </Typography>
                  </ListItemAvatar>
                  <ListItemText
                    primary={codbarra.cod_barra}
                    secondary={codbarra.grade_itens
                      .map((gradeItem) => gradeItem.descricao)
                      .join(", ")}
                  />
                </ListItemButton>
                <Tooltip title="Remover">
                  <IconButton
                    onClick={() => handleDeleteCodBarra(index)}
                    size="large"
                    sx={{
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        height: "80%",
                        display: "block",
                        left: 0,
                        width: "1px",
                        bgcolor: "divider",
                      },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </ListItem>
            </Collapse>
          ))}
        </TransitionGroup>
      </List>
      <Button variant="contained" onClick={handleAddCodBarra} sx={{ mt: 1 }}>
        Adicionar Código de Barras
      </Button>
    </Box>
  );
};

export default NuvelProductCodBarraList;
