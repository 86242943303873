import { RouteInterface } from "utils/router";

import RetAgendaForm from "../views/cadastro/agenda/form";
import RetAgendaList from "../views/cadastro/agenda/list";
import RetCatalogoForm from "../views/cadastro/catalogo/form";
import RetCatalogoList from "../views/cadastro/catalogo/list";
import RetDepartamentoForm from "../views/cadastro/departamento/form";
import RetDepartamentoList from "../views/cadastro/departamento/list";
import RetEtiquetasForm from "../views/cadastro/etiquetas/form";
import RetEtiquetasList from "../views/cadastro/etiquetas/list";
import RetLojaForm from "../views/cadastro/loja/form";
import RetLojaList from "../views/cadastro/loja/list";
import RetParceiroForm from "../views/cadastro/parceiro_de_negocios/form";
import RetParceiroList from "../views/cadastro/parceiro_de_negocios/list";
import RetProdutosForm from "../views/cadastro/produtos/form";
import RetProdutosList from "../views/cadastro/produtos/list";
import RetTagForm from "../views/cadastro/tag/form";
import RetTagList from "../views/cadastro/tag/list";
import RetUserForm from "../views/cadastro/usuario/form";
import RetUserList from "../views/cadastro/usuario/list";

const cadastroRoutes: RouteInterface[] = [
  {
    title: "Usuário",
    path: "/retaguarda/cadastro/usuario",
    element: <RetUserList />,
    form: <RetUserForm />,
    perm: "CADASTRO_USUARIO",
  },
  {
    title: "Loja",
    path: "/retaguarda/cadastro/loja",
    element: <RetLojaList />,
    form: <RetLojaForm />,
    perm: "CADASTRO_LOJA",
  },
  {
    title: "Agenda",
    path: "/retaguarda/cadastro/agenda",
    element: <RetAgendaList />,
    form: <RetAgendaForm />,
    perm: "CADASTRO_AGENDA",
  },
  {
    title: "Parceiro de Negócios",
    path: "/retaguarda/cadastro/parceiro",
    element: <RetParceiroList />,
    form: <RetParceiroForm />,
    perm: "CADASTRO_PARCEIRO",
  },
  {
    title: "Produtos",
    path: "/retaguarda/cadastro/produtos",
    element: <RetProdutosList />,
    form: <RetProdutosForm />,
    perm: "CADASTRO_PRODUTOS",
  },
  {
    title: "Departamento",
    path: "/retaguarda/cadastro/departamento",
    element: <RetDepartamentoList />,
    form: <RetDepartamentoForm />,
    perm: "CADASTRO_DEPARTAMENTO",
  },
  {
    title: "Tag",
    path: "/retaguarda/cadastro/tag",
    element: <RetTagList />,
    form: <RetTagForm />,
    perm: "CADASTRO_TAG",
  },
  {
    title: "Etiquetas (BETA)",
    path: "/retaguarda/cadastro/etiquetas",
    element: <RetEtiquetasList />,
    form: <RetEtiquetasForm />,
    perm: "CADASTRO_ETIQUETAS",
  },
  {
    title: "Catálogo",
    path: "/retaguarda/cadastro/catalogo",
    element: <RetCatalogoList />,
    form: <RetCatalogoForm />,
    perm: "CATALOGO",
  },
];

export default cadastroRoutes;
