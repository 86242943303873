import { RouteInterface } from "utils/router";

import RetReportCaixaForm from "../views/relatorios/caixa/form";
import RetReportVendasForm from "../views/relatorios/vendas/form";
import RetReportProdutosForm from "../views/relatorios/produtos/form";
import RetReportLojasForm from "../views/relatorios/lojas/form";
import RetReportContasForm from "../views/relatorios/contas/form";
import RetReportFiscalForm from "../views/relatorios/fiscal/form";
import RetReportParceiroForm from "../views/relatorios/parceiro/form";
import RetReportRequestList from "../views/relatorios/requests/list";
const relatoriosRoutes: RouteInterface[] = [
  {
    title: "Relatórios solicitados",
    path: "/retaguarda/relatorios/requests",
    element: <RetReportRequestList />,
    perm: "RELATORIOS_REQUESTS",
  },
  {
    title: "Caixa",
    path: "/retaguarda/relatorios/caixa",
    element: <RetReportCaixaForm />,
    perm: "RELATORIOS_CAIXA",
  },
  {
    title: "Vendas",
    path: "/retaguarda/relatorios/vendas",
    element: <RetReportVendasForm />,
    perm: "RELATORIOS_VENDAS",
  },
  {
    title: "Produtos",
    path: "/retaguarda/relatorios/produtos",
    element: <RetReportProdutosForm />,
    perm: "RELATORIOS_PRODUTOS",
  },
  {
    title: "Lojas",
    path: "/retaguarda/relatorios/lojas",
    element: <RetReportLojasForm />,
    perm: "RELATORIOS_LOJAS",
  },
  {
    title: "Contas",
    path: "/retaguarda/relatorios/contas",
    element: <RetReportContasForm />,
    perm: "RELATORIOS_CONTAS",
  },
  {
    title: "Fiscal",
    path: "/retaguarda/relatorios/fiscal",
    element: <RetReportFiscalForm />,
    perm: "RELATORIOS_FISCAL",
  },
  {
    title: "Parceiros",
    path: "/retaguarda/relatorios/parceiro",
    element: <RetReportParceiroForm />,
    perm: "RELATORIOS_PARCEIRO",
  },
];

export default relatoriosRoutes;
