import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Chip,
  ChipProps,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import { InternalModel, MainInterface } from "data/main";
import { useAppContext } from "hooks";
import useDebounce from "hooks/useDebounce";
import * as React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DefaultTableHead from "./tableHead";
import DefaultTableToolbar from "./toolbar";
import { useMemo } from "react";

// 1. Base for shared fields.
interface BaseTableColumn<T> {
  label: string;
  accessor: keyof T | ((row: T) => string | number | React.ReactNode | null);
  minWidth?: number;
  type?:
    | "string"
    | "number"
    | "date"
    | "datetime"
    | "time"
    | "money"
    | "percent";
  align?: "right" | "left" | "center";
  component?: "chip" | "download";
  componentProps?: unknown;
}

interface ChipTableColumn<T> extends BaseTableColumn<T> {
  component?: "chip";
  componentProps?: ChipProps | ((row: T) => ChipProps);
}

interface DownloadProps<T> {
  onClick?: (row: T) => Promise<void>;
  disabled?: boolean;
  label?: string;
}

interface DownloadTableColumn<T> extends BaseTableColumn<T> {
  component?: "download";
  componentProps?: DownloadProps<T> | ((row: T) => DownloadProps<T>);
}

const DownloadAction = <T,>({
  onClick,
  disabled,
  label,
  row,
}: DownloadProps<T> & { row: T }) => {
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    if (!onClick) return;
    setLoading(true);
    await onClick(row);
    setLoading(false);
  };

  const finalLabel = useMemo((): React.ReactNode => {
    if (loading)
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress
            size={20}
            sx={(theme) => ({ color: theme.palette.text.primary })}
          />
          <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
            {label || "Download"}
          </Typography>
        </Box>
      );

    return label || "Download";
  }, [loading, label]);

  return (
    <Chip
      label={finalLabel}
      onClick={handleClick}
      disabled={disabled || loading}
    />
  );
};

// 3. Combine them into a union type.
export type TableColumn<T> = ChipTableColumn<T> | DownloadTableColumn<T>;

export interface TableExtraAddOptions {
  icon?: React.ReactNode;
  label: string;
  function: (def: () => void) => void;
}

export interface TableFilter<T> {
  label: string;
  accessor?: keyof T;
  support?: string[];
  select?: { label: string; accessor: keyof T | "todos" }[];
}

export interface TableActions<L> {
  update?: boolean;
  delete?: boolean;
  create?: boolean;
  custom?: (row: L) => React.ReactNode;
}

interface NuvelDefaultListProps<
  T extends InternalModel,
  L extends InternalModel
> {
  model: MainInterface<T, L>;
  columns: TableColumn<L>[];
  filters?: TableFilter<L>[];
  ordering?: (keyof L)[];
  actions?: TableActions<L>;
  extraAddOptions?: TableExtraAddOptions[];
  captureMutate?: React.Dispatch<React.SetStateAction<() => void>>;
}

export type Order = "asc" | "desc";

const NuvelDefaultList = <T extends InternalModel, L extends InternalModel>({
  model,
  columns,
  filters,
  ordering,
  actions,
  extraAddOptions,
  captureMutate,
}: NuvelDefaultListProps<T, L>) => {
  const navigate = useNavigate();
  const {
    dialog: { showConfirm },
    showSnack,
  } = useAppContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof L | null>(
    ordering ? ordering[0] : null
  );
  const [urlParams, setUrlParams] = useSearchParams();
  const [page, setPage] = React.useState(Number(urlParams.get("page")) || 1);
  const [rawSearch, setRawSearch] = React.useState("");
  const search = useDebounce(rawSearch, 500);
  const [searchBase, setSearchBase] = React.useState<{
    search_type: string;
    search_field: keyof L;
  }>();
  const [selectFilters, setSelectFilters] = React.useState<
    Record<string, boolean>
  >({});

  const rowsPerPage = 10;

  const modelFilters = {
    page: page,
    page_size: rowsPerPage,
    ordering: order === "asc" ? orderBy : `-${String(orderBy)}`,
    search: search,
    ...(searchBase && {
      [`${String(searchBase?.search_field)}__${String(
        searchBase?.search_type
      )}`]: search,
    }),
    ...Object.entries(selectFilters)
      .filter(([, value]) => value === true)
      .reduce((acc, [key]) => ({ ...acc, [key]: true }), {}),
  };

  const { data, isValidating, mutate } = model.useModel(modelFilters);

  const [previousData, setPreviousData] = React.useState(data);

  const location = useLocation();
  const absLocation = location?.pathname + location?.search;

  React.useEffect(() => {
    if (!isValidating && data) {
      setPreviousData(data);
    }
  }, [data, isValidating]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof L
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    setUrlParams(new URLSearchParams({ page: String(newPage) }));
  };

  function ColumnComponent({
    column,
    children,
    row,
  }: {
    column: TableColumn<L>;
    row: L;
    children: React.ReactNode;
  }): React.ReactNode {
    switch (column.component) {
      case "chip":
        return (
          <Chip
            {...(typeof column.componentProps === "function"
              ? column.componentProps(row)
              : column.componentProps)}
            label={children}
          />
        );
      case "download":
        return (
          <DownloadAction
            {...(typeof column.componentProps === "function"
              ? column.componentProps(row)
              : column.componentProps)}
            row={row}
          />
        );
      default:
        return <>{children}</>;
    }
  }

  const EditAction = (row: L) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        navigate(window.location.pathname + "/" + row.id, {
          state: { exitRoute: absLocation },
        });
      }}
    >
      <Edit color="primary" />
    </IconButton>
  );

  const RemoveAction = (row: L) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        showConfirm(
          "Deseja realmente excluir?",
          "Exclusão",
          async (confirm) => {
            if (confirm) {
              await model.delete(Number(row.id));
              mutate();
              showSnack("Excluído com sucesso!", 2, "success");
            }
          }
        );
      }}
    >
      <Delete color="error" />
    </IconButton>
  );

  const handleOptionsClick = (func: (def: () => void) => void) => {
    func(() => {
      navigate(window.location.pathname + "/novo", {
        state: { exitRoute: absLocation },
      });
    });
    handleCloseOptions();
  };

  const data_rows = previousData?.rows || [];
  const rows_counts = previousData?.totalCount || 0;
  const total_pages = Math.ceil(rows_counts / rowsPerPage);

  React.useEffect(() => {
    if (mutate && captureMutate && data_rows.length !== 0) {
      captureMutate(() => mutate); // Passando a função mutate e não o resultado da execução
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_rows]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <PaperWithLoad
        loading={(!data && !previousData) || isValidating}
        sx={{
          width: "100%",
          borderRadius: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DefaultTableToolbar
          filters={filters}
          rawSearch={rawSearch}
          setRawSearch={setRawSearch}
          searchBase={searchBase}
          setSearchBase={setSearchBase}
          selectFilters={selectFilters}
          setSelectFilters={setSelectFilters}
        />
        <Divider variant="middle" />
        <TableContainer sx={{ flexGrow: 1 }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <DefaultTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
              ordering={ordering}
              actions={
                actions?.update ||
                actions?.delete ||
                (actions?.custom ? true : false)
              }
            />
            <TableBody>
              {data_rows.map((row: L, index: number) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                  sx={{ cursor: actions?.update ? "pointer" : "default" }}
                  onClick={() => {
                    if (actions?.update) {
                      navigate(window.location.pathname + "/" + row.id, {
                        state: { exitRoute: absLocation },
                      });
                    }
                  }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.accessor as string}
                      align={column.align || "left"}
                      style={{ minWidth: column.minWidth }}
                    >
                      <ColumnComponent column={column} row={row}>
                        {typeof column.accessor === "function"
                          ? column.accessor(row)
                          : null}
                        {String(
                          typeof column.accessor !== "function"
                            ? column.type === "datetime"
                              ? new Date(
                                  String(row[column.accessor])
                                ).toLocaleString()
                              : column.type === "date"
                              ? new Date(
                                  String(row[column.accessor])
                                ).toLocaleDateString()
                              : column.type === "time"
                              ? new Date(
                                  String(row[column.accessor])
                                ).toLocaleTimeString()
                              : column.type === "number"
                              ? Number(row[column.accessor]).toLocaleString()
                              : column.type === "money"
                              ? Number(row[column.accessor]).toBRL()
                              : column.type === "percent"
                              ? Number(row[column.accessor]).toDecimal(2)
                              : row[column.accessor]
                            : ""
                        )}
                      </ColumnComponent>
                    </TableCell>
                  ))}
                  {actions?.update ||
                  actions?.delete ||
                  (actions?.custom ? true : false) ? (
                    <TableCell align="center" padding="none">
                      {actions?.update ? EditAction(row) : null}
                      {actions?.delete ? RemoveAction(row) : null}
                      {actions?.custom ? actions.custom(row) : null}
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider variant="middle" />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: 56 }}
        >
          <Pagination
            page={page}
            onChange={handleChangePage}
            count={total_pages}
            variant="outlined"
            color="primary"
          />
        </Box>
      </PaperWithLoad>
      {actions && actions.create && !extraAddOptions ? (
        <Fab
          color="primary"
          onClick={() => {
            navigate(window.location.pathname + "/novo", {
              state: { exitRoute: absLocation },
            });
          }}
          sx={{
            position: "fixed",
            bottom: 32,
            right: 32,
            width: 72,
            height: 72,
          }}
        >
          <Add sx={{ fontSize: 72 / 1.5 }} />
        </Fab>
      ) : null}
      {extraAddOptions ? (
        <>
          <Fab
            color="primary"
            onClick={handleClickOptions}
            sx={{
              position: "fixed",
              bottom: 32,
              right: 32,
              width: 72,
              height: 72,
            }}
          >
            <Add sx={{ fontSize: 72 / 1.5 }} />
          </Fab>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseOptions}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {actions && actions.create ? (
              <MenuItem
                onClick={() => {
                  navigate(window.location.pathname + "/novo", {
                    state: { exitRoute: absLocation },
                  });
                }}
              >
                Adicionar Novo
              </MenuItem>
            ) : null}
            {extraAddOptions.map((extra) => (
              <MenuItem
                key={extra.label}
                onClick={() => handleOptionsClick(extra.function)}
              >
                {extra.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : null}
    </Box>
  );
};

export default NuvelDefaultList;
