import { Box } from "@mui/material";
import { CodBarraSerializer } from "data/interfaces/estoque/CodBarraSerializer";
import { GradeItemSerializer } from "data/interfaces/estoque/GradeItemSerializer";
import { GradeSerializer } from "data/interfaces/estoque/GradeSerializer";
import { useState } from "react";
import NuvelProductCodBarraDialog from "./NuvelProductCodBarraDialog";
import NuvelProductCodBarraList from "./NuvelProductCodBarraList";
import { SelectedCodBarraInterface } from "./types";

interface NuvelProductCodBarraComponentProps {
  codbarras: CodBarraSerializer[];
  grades: GradeSerializer[];
  onChangeCodBarra: (codbarras: CodBarraSerializer[]) => void;
}

const NuvelProductCodBarraComponent = ({
  codbarras,
  grades,
  onChangeCodBarra,
}: NuvelProductCodBarraComponentProps) => {
  const [selectedCodBarra, setSelectedCodBarra] =
    useState<SelectedCodBarraInterface | null>(null);

  const handleSaveCodBarra = () => {
    if (!selectedCodBarra) return;
    codbarras[selectedCodBarra.index] = selectedCodBarra.codBarra;
    onChangeCodBarra(codbarras);
    setSelectedCodBarra(null);
  };

  const handleCloseDialog = () => {
    if (selectedCodBarra?.isNew) {
      onChangeCodBarra(
        codbarras.filter((_, i) => i !== selectedCodBarra.index)
      );
    }
    setSelectedCodBarra(null);
  };

  const checkExistingGradeItems = (gradeItems: GradeItemSerializer[]) => {
    if (gradeItems.length !== grades.length) return undefined;
    return codbarras.find((codBarra) => {
      if (codBarra.grade_itens.length !== grades.length) return false;
      return codBarra.grade_itens.every((gradeItem) =>
        gradeItems.some((item) => item.id === gradeItem.id)
      );
    });
  };

  return (
    <Box>
      <NuvelProductCodBarraDialog
        open={!!selectedCodBarra}
        onClose={handleCloseDialog}
        codBarra={selectedCodBarra?.codBarra || null}
        onChangeCodBarra={(codBarra) => {
          setSelectedCodBarra({
            ...selectedCodBarra!,
            codBarra,
          });
        }}
        onSave={handleSaveCodBarra}
        grades={grades}
        checkExistingGradeItems={checkExistingGradeItems}
      />
      <NuvelProductCodBarraList
        codbarras={codbarras}
        onChangeCodBarra={onChangeCodBarra}
        grades={grades}
        onSelectCodBarra={(codBarra, isNew, index) => {
          setSelectedCodBarra({
            index,
            codBarra,
            isNew,
          });
        }}
      />
    </Box>
  );
};

export default NuvelProductCodBarraComponent;
