import NuvelAutocomplete, {
  NuvelAutocompleteEvent,
} from "components/nuvel/Autocomplete";
import Grid from "components/nuvel/Grid";
import Select from "components/nuvel/Select";
import TabPanel from "components/nuvel/TabPanel";
import TextField from "components/nuvel/TextField";
import { NotaDeSaidaSerializer } from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import { ChangeEvent } from "react";

interface TransportadorTabProps {
  tabs: number;
  stateNota: NotaDeSaidaSerializer;
  handleChange: (
    event:
      | NuvelAutocompleteEvent
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => void;
}

const TransportadorTab = ({
  tabs,
  stateNota,
  handleChange,
}: TransportadorTabProps) => {
  return (
    <TabPanel value={tabs} index={1}>
      <Grid container spacing={1}>
        <Grid xs={5}>
          <NuvelAutocomplete
            label="Transportador"
            name="transportador"
            value={Number(stateNota.transportador)}
            onChange={(event, value) =>
              handleChange(event, value?.toString() || "")
            }
            reference="transportador"
          />
        </Grid>
        <Grid xs={3}>
          <Select
            label="Frete por Conta"
            name="transportador_frete"
            value={stateNota.transportador_frete}
            onChange={(event) => handleChange(event, event.target.value)}
            options={[
              { label: "Emitente", value: 0 },
              { label: "Destinatário", value: 1 },
              { label: "Terceiros", value: 2 },
              { label: "Sem Frete", value: 9 },
            ]}
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            label="Placa"
            name="transportador_placa"
            value={stateNota.transportador_placa}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            label="UF"
            name="transportador_uf"
            value={stateNota.transportador_uf}
            onChange={handleChange}
            normalize={(str) => str.toUpperCase()}
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            label="Quantidade"
            name="transportador_quantidade"
            value={stateNota.transportador_quantidade}
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            label="Espécie"
            name="transportador_especie"
            value={stateNota.transportador_especie}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            label="Marca"
            name="transportador_marca"
            value={stateNota.transportador_marca}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            type="number"
            label="Número"
            name="transportador_numero"
            value={stateNota.transportador_numero}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            decimal
            label="Peso Bruto"
            name="transportador_peso_bruto"
            value={stateNota.transportador_peso_bruto}
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            decimal
            label="Peso Líquido"
            name="transportador_peso_liquido"
            value={stateNota.transportador_peso_liquido}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default TransportadorTab;
