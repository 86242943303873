import React, { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Box, IconButton, Slide, TextField } from "@mui/material";
import { useCatalog } from "layouts/catalogo/context";
import { Close, Search } from "@mui/icons-material";

const DepartamentsTabBar: React.FC = () => {
  const [value, setValue] = useState(0);
  const { state } = useCatalog();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsSearchOpen(false);
      }
    };

    if (isSearchOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;
            const departmentIndex = state.departments?.findIndex(
              (dept) => `section-${dept.id}` === sectionId
            );
            if (departmentIndex !== -1) {
              setValue(departmentIndex);
            }
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: "50px 0px 0px 0px",
      }
    );

    state.departments?.forEach((dept) => {
      const element = document.getElementById(`section-${dept.id}`);
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, [state.departments]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    const department = state.departments[newValue];
    const sectionElement = document.getElementById(`section-${department.id}`);

    sectionElement?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        position: "sticky",
        top: 0,
        bgcolor: "#F3F3F3",
        zIndex: 1000,
        display: state.activeWindow === "catalog" ? "block" : "none",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
        {!isSearchOpen && (
          <IconButton
            size="small"
            onClick={() => setIsSearchOpen(true)}
            sx={{ mr: 1 }}
          >
            <Search />
          </IconButton>
        )}
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
            transition: "width 0.3s ease",
            width: isSearchOpen ? "0%" : "100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="departamentos"
            sx={{ height: 32, minHeight: 32 }}
          >
            {state.departments?.map((dept) => (
              <Tab
                sx={{ pb: 0.5, pt: 0.5, height: 32, minHeight: 32 }}
                key={dept.id}
                label={dept.name}
              />
            ))}
          </Tabs>
        </Box>

        <Slide direction="right" in={isSearchOpen} mountOnEnter unmountOnExit>
          <TextField
            ref={searchRef}
            autoFocus
            variant="standard"
            placeholder="Pesquisar..."
            sx={{
              width: "100%",
              position: "absolute",
              left: 0,
              right: 0,
              backgroundColor: "#F3F3F3",
              padding: "0 8px",
              "& .MuiInput-root": {
                height: 32,
              },
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => setIsSearchOpen(false)}
                  >
                    <Close />
                  </IconButton>
                ),
              },
            }}
          />
        </Slide>
      </Box>
    </Box>
  );
};

export default DepartamentsTabBar;
