import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelDatePicker from "components/nuvel/DatePicker";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import DefaultRelatorios from "components/retaguarda/relatorios";
import dayjs from "dayjs";
import React from "react";
import { handleChange } from "utils/functions";

interface FiscalState {
  relatorio: number;
  data_inicio: dayjs.Dayjs;
  data_fim: dayjs.Dayjs;
  cliente: string;
  vendedor: string;
  cstat: number;
  lojas: number[];
  tags: number[];
  serie: string;
}

const getStatus = (status: number): string => {
  return (
    [
      "Recebido",
      "Enviado",
      "Enviado Contingencia",
      "Cancelado",
      "Erro Sefaz",
      "Contingencia",
      "Cancelado sem finalizar",
    ][status] || ""
  );
};

const relatorios = (state: FiscalState) => [
  {
    url: "fiscal/nfce_chaves",
    title: "Relatório de Chaves NFC-e",
    columns: [
      {
        label: "Data",
        accessor: "data_fechamento",
        flex: 1,
        type: "datetime",
      },
      { label: "Série", accessor: "serie", flex: 1 },
      { label: "Número", accessor: "numero", flex: 1 },
      { label: "Chave", accessor: "chave_acesso", flex: 6 },
      { label: "Total", accessor: "total", flex: 2, type: "money" },
    ],
    filtros: {
      data_inicio: dayjs(state.data_inicio).format(),
      data_fim: dayjs(state.data_fim).endOf("day").format(),
      lojas: state.lojas.join(","),
      cstat: state.cstat === 0 ? undefined : state.cstat,
      tags: state.tags.join(","),
      serie: state.serie === "" ? undefined : state.serie,
      cliente: state.cliente === "" ? undefined : state.cliente,
      vendedor: state.vendedor === "" ? undefined : state.vendedor,
    },
    totais: "total",
  },
  {
    url: "fiscal/relacao_contingencia",
    title: "Relação de Contingências",
    columns: [
      {
        label: "Data",
        accessor: "data_fechamento",
        flex: 2,
        type: "datetime",
      },
      { label: "Série", accessor: "serie", flex: 2 },
      { label: "Número", accessor: "numero", flex: 1 },
      {
        label: "Status",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        accessor: (row: any) => getStatus(row.status),
        flex: 2,
      },
      { label: "Cstat", accessor: "cstat", flex: 1 },
      { label: "Xmotivo", accessor: "xmotivo", flex: 6 },
    ],
    filtros: {
      data_inicio: dayjs(state.data_inicio).format(),
      data_fim: dayjs(state.data_fim).endOf("day").format(),
      lojas: state.lojas.join(","),
      cstat: state.cstat === 0 ? undefined : state.cstat,
      tags: state.tags.join(","),
      serie: state.serie === "" ? undefined : state.serie,
    },
    totais: "",
  },
];

const RetReportFiscalForm: React.FC = () => {
  const [state, setState] = React.useState<FiscalState>({
    relatorio: 0,
    data_inicio: dayjs().startOf("day"),
    data_fim: dayjs().endOf("day"),
    cliente: "",
    vendedor: "",
    cstat: 0,
    lojas: [],
    tags: [],
    serie: "",
  });

  return (
    <DefaultRelatorios
      title="Fiscal"
      relatorio={state.relatorio}
      relatorios={relatorios(state)}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={4}>
          <NuvelSelect
            label="Relatório"
            name="relatorio"
            onChange={(e) => handleChange(e, setState)}
            value={state.relatorio}
            options={relatorios(state).map((rel, index) => ({
              value: index,
              label: rel.title,
            }))}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelDatePicker
            label="Data Início"
            onChange={(e) => handleChange(e, setState)}
            name="data_inicio"
            value={state.data_inicio}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelDatePicker
            label="Data Fim"
            onChange={(e) => handleChange(e, setState)}
            name="data_fim"
            value={state.data_fim}
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelAutocomplete
            label="Cliente"
            reference="cliente"
            name="cliente"
            value={state.cliente}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelAutocomplete
            label="Vendedor"
            name="vendedor"
            reference="funcionario"
            value={state.vendedor}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelAutocomplete
            name="loja"
            reference="loja"
            label="Lojas"
            value={state.lojas}
            onChange={(_, v) => setState({ ...state, lojas: v as number[] })}
            multiple
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelSelect
            label="Situação"
            name="cstat"
            onChange={(e) => handleChange(e, setState)}
            value={state.cstat}
            options={[
              { label: "Todas", value: 0 },
              { label: "Ativas", value: 100 },
              { label: "Canceladas", value: 101 },
            ]}
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelAutocomplete
            name="tags"
            reference="tags"
            label="Tags"
            value={state.tags}
            onChange={(_, v) => setState({ ...state, tags: v as number[] })}
            multiple
          />
        </NuvelGrid>
        <NuvelGrid xs={2}>
          <NuvelTextField
            label="Série"
            name="serie"
            type="number"
            onChange={(e) => handleChange(e, setState)}
            value={state.serie}
          />
        </NuvelGrid>
      </NuvelGrid>
    </DefaultRelatorios>
  );
};

export default RetReportFiscalForm;
