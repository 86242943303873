import { ProdutoListSerializer } from "data/interfaces/estoque/ProdutoListSerializer";
import { NotaDeSaidaItensSerializer } from "data/interfaces/financeiro/NotaDeSaidaItensSerializer";
import { NotaDeSaidaPagamentosSerializer, NotaDeSaidaSerializer } from "data/interfaces/financeiro/NotaDeSaidaSerializer";

export const getSingleProd = (
  prodId: number,
  listProd: {
    rows: ProdutoListSerializer[];
  }
) => {
  return listProd?.rows.find((x) => x.id === prodId);
};

export const getTotaisProd = (
  prod: NotaDeSaidaItensSerializer,
  listProd: {
    rows: ProdutoListSerializer[];
  }
) => {
  const subtotal =
    Number(getSingleProd(Number(prod.produto), listProd)?.preco_venda) *
    Number(prod.quantidade);
  const calc_desc = (Number(prod.desconto) / 100) * subtotal;
  const calc_acres = (Number(prod.acrescimo) / 100) * subtotal;
  const total = subtotal + calc_acres - calc_desc + Number(prod.frete || 0);
  return {
    subtotal: subtotal,
    total: total,
    desconto: calc_desc,
    acrescimo: calc_acres,
  };
};

export const getTotaisNota = (
  itens: NotaDeSaidaItensSerializer[],
  pagamentos: NotaDeSaidaPagamentosSerializer[],
  listProd: {
    rows: ProdutoListSerializer[];
  }
): Pick<
  NotaDeSaidaSerializer,
  "total" | "subtotal" | "total_desconto" | "total_acrescimo" | "total_pagamentos" | "total_troco"
> => {
  let total = 0;
  let subtotal = 0;
  let desconto = 0;
  let acrescimo = 0;
  let total_pagamentos = 0;
  let total_troco = 0;
  itens.forEach((prod) => {
    const totaisProd = getTotaisProd(prod, listProd);
    total += totaisProd.total;
    subtotal += totaisProd.subtotal;
    desconto += totaisProd.desconto;
    acrescimo += totaisProd.acrescimo;
  });
  pagamentos.forEach((pag) => {
    total_pagamentos += Number(pag.valor);
    total_troco += Number(pag.troco);
  });
  return {
    total: total.round(2),
    subtotal: subtotal.round(2),
    total_desconto: desconto.round(2),
    total_acrescimo: acrescimo.round(2),
    total_pagamentos: total_pagamentos.round(2),
    total_troco: total_troco.round(2),
  };
};
